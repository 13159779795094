/* Home Page CSS */
.HeaderHome {
    background-image: url("./header-home.webp");
    background-size:cover;
    background-position: center bottom;
  }
.HeaderCardBg {
background: rgba(247, 248, 250, 0.8);
box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
backdrop-filter: blur(24px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 16px;
max-width: 730px;
z-index: 1000;
}
.HeaderCardBg h1 {
    color: #3C3D41 !important;
}
.HeaderBikers {
    margin-top: -110px;
    /* z-index: 1; */
    min-height:500px !important;
    position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}
.HeaderBikersSm {
    margin-top: 50px;
    /* z-index: 1; */
    position: relative;
}
.BuildCardsBold {
    font-family: Inter;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 28px;
/* identical to box height, or 156% */

text-align: center;
font-feature-settings: 'salt' on, 'liga' off;

/* Text / Gray 900 */

color: #18191F;
}
#BuildRacingTeams {
    background: rgba(244, 244, 244, 0.5);
}
.sectionHeading {
    font-family: 'Bebas Neue', cursive;
    font-size: calc(1.60rem + 1.50vw);
    margin-top: -75px;
    margin-bottom: 20px;
}
.sectionHeadingSm {
    font-family: 'Bebas Neue', cursive;
    font-size: calc(1.60rem + 1.50vw);

}
.TeamRoleTabs.nav.nav-tabs{
    margin-top: -50px!important;
    border: none !important;
    float:right;
    height: 48px;
    background: rgba(233, 233, 233, 0.59);
    border-radius: 24px;
    padding-left: 8px;
    padding-right: 8px;
    width: 290px;
}
.RoleToggleBox {
    height: 48px;
    background: rgba(233, 233, 233, 0.59);
    border-radius: 24px;
}
.TeamRoleTabs .nav-item {
    border: none !important;
    height: 32px !important;

}
.TeamRoleTabs
    .nav-link.active {
    border: none !important;
    border-radius: 24px;
    background-image: url("./toggle-team-role.png");
    color: #C253B1;
    font-weight: 500;
}
.TeamRoleTabs
    .nav-link {
    border: none !important;
    height: 100%;
    border-radius: 24px;
    color: black;
    margin: auto !important;
    /* padding-left: 45px; */
    padding: 4px 0px 0px 15px !important;
    width: 137px;
}
.TeamHeader {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #18191F;
}

.ChampsTeamCardActive {
    border-color: black;
    border-width: 2px;
    border-style: solid;
    border-radius: 105px;
}

#TheTeams .card {
    max-width: 288px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 8px;
    transition: 0.5s;
    cursor: pointer;
    height: 100%;
}
#TheTeams .card:hover {
    transform:scale(1.03);
    transition: 0.5s;
}
#TheTeams .card
            .SmallText {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #000000;
}
#TheTeams .card
            .GreenText {
                font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 24px;
margin-top: 12px;
font-feature-settings: 'salt' on, 'liga' off;
color: #8E930C;
            }
#TheTeams .card
            .Pill {
width:fit-content;
padding-left: 10px;
padding-right: 10px;
height: 23px;
color: white;
border-radius: 40px;
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 23px;
position: relative;
bottom: -12px;
}

#TheTeams .card:target {
    border-color: black;
    border-width: 2px;
    border-style: solid;
    border-radius: 105px;
}

.RoleHeader {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #18191F;
}
#TheRoles .card {
    max-width: 300px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 8px;
    transition: 0.5s;
    cursor: pointer;
    height: 100%;
}
#TheRoles .card:hover {
    transform:scale(1.03);
    transition: 0.5s;
}
#TheRoles .card
            .SmallText {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #000000;
}
#TheRoles .card
            .GreenText {
                font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 24px;
margin-top: 12px;
font-feature-settings: 'salt' on, 'liga' off;
color: #8E930C;
            }
.bottom-icons.carousel-control-next-icon {
    height: 40px;
    width: 40px;
    bottom: -40px !important;
    position: absolute;
    color: black !important;
    background-image: url(./images/next-carousel.svg);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e") */
}
.bottom-icons.carousel-control-prev-icon {
    height: 40px;
    width: 40px;
    bottom: -40px !important;
    position: absolute;
    color: black !important;
    background-image: url(./images/previous-carousel.svg);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e") */

}
.carousel-control-next-icon {
    height: 40px;
    width: 40px;
    color: black !important;
    background-image: url(./images/next-carousel.svg);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e") */
}
.carousel-control-prev-icon {
    height: 40px;
    width: 40px;
    color: black !important;
    background-image: url(./images/previous-carousel.svg);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e") */

}
/* .role-carousel .carousel-control-next-icon {
    height: 40px;
    width: 40px;
    position: initial;
    color: black !important;
    background-image: url(./images/next-carousel.svg);
}
.role-carousel .carousel-control-prev-icon {
    height: 40px;
    width: 40px;
    position: initial;
    color: black !important;
    background-image: url(./images/previous-carousel.svg);
} */
.PurpleText {
    font-family: Inter;
font-style: normal;
font-weight: bold;
font-size: 16px;
/* identical to box height, or 200% */

font-feature-settings: 'salt' on, 'liga' off;

color: #B74AC9;
}
.OrangeText {
    font-family: Inter;
font-style: normal;
font-weight: bold;
font-size: 16px;
/* identical to box height, or 200% */

font-feature-settings: 'salt' on, 'liga' off;

color: #DA932A;
;
}
.BlueText {
    font-family: Inter;
font-style: normal;
font-weight: bold;
font-size: 16px;
/* identical to box height, or 200% */

font-feature-settings: 'salt' on, 'liga' off;

color: #1467B4;
;
}
.GreenText {
    font-family: Inter;
font-style: normal;
font-weight: bold;
font-size: 16px;
/* identical to box height, or 200% */

font-feature-settings: 'salt' on, 'liga' off;

color: #119235;
;
}