.accordion-style{
    border: none;
    background-color: #F4F4F4;
    margin-bottom: 20px;
}

.accordion-style button {
    font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: black;

}

.accordion-style button:hover{
    
color: #E84F00;

}