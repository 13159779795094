.NeoOneRacing {
    height: auto;
    background-color: #3A3A3A;
    border-radius: 16px;
}
.NeoOneRacing p {
font-family: Inter;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
padding-top: 10px;
color: white;
/* or 200% */

font-feature-settings: 'salt' on, 'liga' off;
}

.NeoOneRacing h1 {
    font-family: Bebas;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 32px;
    color: white;
    /* or 200% */
    }
.holo-title {
    color: #de466c;
    font-family: Bebas;
    font-style: normal;
    font-weight: normal;
    background: url(../../public/pages/images/mint/holographic-background.jpg) 0 0 / cover no-repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: calc(2.2rem + 2.4vw);
    line-height: calc(2.2rem + 2.4vw);
}
