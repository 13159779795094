.standard-card {
    filter: drop-shadow(0px 4px 31px rgba(0, 0, 0, 0.1));
    border-radius: 15px;
}

p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: Inter;
}

.card-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.card-img-top {
    padding: 17px;
}

.card-price {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.card-button {
    background-color: black;
    border-radius: 25px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.price-padding {
    padding-top: 64px;
}

.spacer {
    margin-top: 150px;
    margin-bottom: 150px;
}

/* h1 {
    font-family: 'Bebas Neue', cursive;
    font-size: 32px;
    line-height: 64px;
    color: #18191F;
} */

.concept {
    color: #B74AC9;
}

.concept-number {
    /* Heading/H1 */
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    line-height: 98px;
    /* identical to box height, or 136% */
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    /* Text / Gray 100 */
    color: #F4F5F7;
    margin-bottom: -36px;
}