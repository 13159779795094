
.standard-card {
    filter: drop-shadow(0px 4px 31px rgba(0, 0, 0, 0.1));
    border-radius: 15px;
}

p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: Inter;
}

.card-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.card-img-top {
    padding: 17px;
}

.card-price {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

}

.card-button {
    background-color: black;
    border-radius: 25px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.price-padding {
    padding-top: 64px;
}

.spacer {
    margin-top: 150px;
    margin-bottom: 150px;
}


h3 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: #B74AC9;
}
#ExploreTabs-tab-spectator.nav-item.nav-link:hover, #ExploreTabs-tab-spectator.nav-item.nav-link.active{
    color: #119235 !important;
    border-color: transparent;
    font-weight: 500;
}
#ExploreTabs-tab-spectator.nav-item.nav-link img{
    border-color: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
#ExploreTabs-tab-spectator.nav-item.nav-link:hover img, #ExploreTabs-tab-spectator.nav-item.nav-link.active img{
    border-color: #119235;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
#ExploreTabs-tab-pitCrew.nav-item.nav-link:hover, #ExploreTabs-tab-pitCrew.nav-item.nav-link.active{
    color: #1467B4 !important;
    border-color: transparent;
    font-weight: 500;
} 
#ExploreTabs-tab-pitCrew.nav-item.nav-link img{
    border-color: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
#ExploreTabs-tab-pitCrew.nav-item.nav-link:hover img, #ExploreTabs-tab-pitCrew.nav-item.nav-link.active img{
    border-color: #1467B4;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
#ExploreTabs-tab-crewChief.nav-item.nav-link:hover, #ExploreTabs-tab-crewChief.nav-item.nav-link.active{
    color: #DA932A !important;
    border-color: transparent;
    font-weight: 500;
}
#ExploreTabs-tab-crewChief.nav-item.nav-link img{
    border-color: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
#ExploreTabs-tab-crewChief.nav-item.nav-link:hover img, #ExploreTabs-tab-crewChief.nav-item.nav-link.active img{
    border-color: #DA932A;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
#ExploreTabs-tab-raceDirector.nav-item.nav-link:hover, #ExploreTabs-tab-raceDirector.nav-item.nav-link.active{
    color: #B74AC9 !important;
    border-color: transparent;
    font-weight: 500;
}
#ExploreTabs-tab-raceDirector.nav-item.nav-link img{
    border-color: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
#ExploreTabs-tab-raceDirector.nav-item.nav-link:hover img, #ExploreTabs-tab-raceDirector.nav-item.nav-link.active img{
    border-color: #B74AC9;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
#ExploreTabs-tab-founder.nav-item.nav-link:hover, #ExploreTabs-tab-founder.nav-item.nav-link.active{
    color: #000 !important;
    border-color: transparent !important;
    font-weight: 500;
}
#ExploreTabs-tab-founder.nav-item.nav-link img{
    border-color: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
#ExploreTabs-tab-founder.nav-item.nav-link:hover img, #ExploreTabs-tab-founder.nav-item.nav-link.active img{
    border-color: #000;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
.nav-tabs .nav-link.active {
    border: none;
}
.nav-item {
    border: none !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.nav-link {
    color: black;
    text-decoration: none;
    height: 100%;
}
.nav-tabs {
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.ExploreMintBox {
    background: #F4F4F4;
    border-radius: 16px;
}
.ExploreTabRewards {
    list-style: none !important;
}
.ExploreTabRewards li::before {
    content: '➤';
    color: #B74AC9;
    position: absolute;
    left: 20px;
  }
  .text10 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #3C3D41;
  }