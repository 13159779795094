#RoleSelectorItem img{
    border-color: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 60px;
}
#RoleSelectorItem:hover img {
    border-color: black;
    border-width: 2px;
    border-style: solid;
    border-radius: 105px;
    cursor: pointer;
}

#RoleSelectorItemActive img {
    border-color: black;
    border-width: 2px;
    border-style: solid;
    border-radius: 105px;
}

#RoleSelectorItemActive:hover img {
    cursor: pointer;
}

#RoleSelectorItemActive p {
    font-weight: 700;
}

.NeoChampsTeamCard {
    max-width: 288px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 8px;
    transition: 0.5s;
    cursor: pointer;
    height: 200px;
}
.NeoChampsTeamCard:hover{
    transform:scale(1.03);
    transition: 0.5s;
}

.NeoChampsTeamCardActive {
    
    border-color: black !important;
    border-width: 2px;
    border-radius: 8px;
    border-style: solid;
    height: 200px;
}

.NeoChampsTeamCardDisabled {
    pointer-events: none;
    opacity: 0.4;
    background: #E9E9E9;
    color: black;
    height: 200px;
}