.role-info-nav {
    border-radius: 20px;
    background-color: black;
    color: white;
    margin: auto;
    display: inline-block;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    height: 32px;
    width: 87px;
}

#link {
    font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-decoration: underline;
color: black;
}

#reveal-header-h1 {
    font-size:'32px';
    line-height:'32px';
}

#reveal-header-p {
    font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px;
letter-spacing: 0px;
text-align: center;
}

#reveal-header-p-sub {
    font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 26px;
letter-spacing: 0px;
text-align: center;
}
