.RevealHeaderHome {
    /* background-image: url("../../public/pages/images/reveal-standard/backgrounds/spectator-background.webp"); */
    background-size:cover;
    background-position: center bottom;

    height: 90vh;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

  }

  .vcenter-item{
    display: flex;
    align-items: center;
}

.spectatorStyling {
  color: #119235;
  font-weight: 600;
}

.pitcrewStyling {
  color: #956113;
  font-weight: 600;
}

.crewchiefStyling {
  color: #1467B4;
  font-weight: 600;
}

.racedirectorStyling {
  color: #B74AC9;
  font-weight: 600;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
    }
}

#avatar-clickable{
  cursor: pointer;
}

.selected{
  opacity: 100%;
}
.not-selected {
  opacity: 50%;
}

