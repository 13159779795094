.start-collection-header {
    padding-top: 80px;
    padding-bottom: 80px;
    font-family: 'Bebas Neue', cursive;
    font-size: 32px;
    color: #18191F;
}

.standard-card {
    filter: drop-shadow(0px 4px 31px rgba(0, 0, 0, 0.1));
    border-radius: 15px;
}

p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: Inter;
}

.card-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.card-img-top {
    padding: 17px;
}

.card-price {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

}

.card-button {
    background-color: black;
    border-radius: 25px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.price-padding {
    padding-top: 64px;
}

.spacer {
    margin-top: 150px;
    margin-bottom: 150px;
}

/* h1 {
    font-family: 'Bebas Neue', cursive;
    font-size: 32px;
    line-height: 64px;
    color: #18191F;
} */

h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
}

h3 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    color: #000000;
}

h4 {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
}

.benefitsvalue {
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    font-family: Inter;
}

.ticket-title {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: black;

}
.ticket-subtitle {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    font-family: Inter;
}

.spectator-small-title {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

}

.spectator-small-subtitle {
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;

}
.additionalbenefits {
    color: #299D49;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
}

.odds-separator {
    height: 32px;
    background-color: #F7C0A3;
    line-height: 32px;
    width: 100%;
    border-radius: 20px;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;

}

.row-margin {
    margin-bottom: 24px;
}

.ticket {
    filter: drop-shadow(0px 4px 31px rgba(0, 0, 0, 0.15));
    border-radius: 10px;
}