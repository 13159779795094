body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* *{border: 1px red solid} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body a {
  color: #000;
  text-decoration: underline;
}
body a:hover {
  color: #E84F00;
}
h1 {
  font-family: 'Bebas Neue', cursive;
  font-size: calc(1.25rem + 1.25vw);
  line-height: 64px;
  color: #18191F;
}

p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: Inter;
  color: #1D252C;
}
.btn-dark {
  height: 48px;
  background-color: black;
  border-radius: 24px;
  padding-left: 32px;
  padding-right: 32px;
}
.btn-outline-dark {
  background-image: url(./components/Home/Rectangle1.png) !important;
  background-color: white !important;
  width: 195px;
  border: none;
  color: #C253B0;
  border-radius: 24px;
  height: 48px;
}
.btn-outline-dark:hover {
  color: #C253B0;
  font-weight: 500;
}
.btn-outline-dark-wide {
  background-image: url(./components/Home/Rectangle1-wide.png) !important;
  background-color: white !important;
  width: 288px;
  border: none;
  color: #C253B0;
  border-radius: 24px;
  height: 48px;
}
.btn-outline-dark-wide:hover {
  color: #C253B0;
  font-weight: 500;
}
.bannerBelowBreadcrumb {
  height: calc(3rem + 6vh);
  background-image: url("./functions/banner-background.webp");
  background-size: cover;
}
.breadcrumb {
  margin-bottom: 0px !important;
  background-color: white;
}
.breadcrumb-item a{
  color: #6D6E71 !important;
}
.breadcrumb-item a:hover{
  color: #E84F00 !important;
  text-decoration: none;
}
.breadcrumb-item+.breadcrumb-item::before {
  content: ">" !important;
}